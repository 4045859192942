<template>
  <div class="is-flex is-flex-direction-column has-background-white px-5 py-5">

    <p class="is-uppercase heading-text-size has-text-grey has-text-weight-medium mb-3">Редактировать пользователя</p>
    <b>Имя</b>
    <input
      class="input"
      type="text"
      v-model.trim="UserData.first_name"
      required
    >
    <b>Фамилия</b>
    <input
      class="input"
      type="text"
      v-model.trim="UserData.last_name"
      required
    >
    <b>Логин</b>
    <input
      class="input"
      type="text"
      v-model.trim="UserData.login"
      required
    >
    <b>Email</b>
    <input
      class="input"
      type="text"
      v-model.trim="UserData.email"
      required
    >
    <b>Номер телефона</b>
    <input
      class="input"
      type="number"
      v-model.trim="UserData.phone"
      required
    >
    <b>Должность</b>
    <input
      class="input"
      type="text"
      v-model.trim="UserData.position"
      required
    >
    <b>Пароль (необязательно)</b>
    <input
      class="input"
      type="password"
      v-model.trim="password"
    >

    <b-field
      label="Роль пользователя"
      class="mt-3"
      v-if="UserData.role"
    >
      <b-select
        placeholder="Выберите компанию"
        v-model="UserData.role.id"
      >
        <option
          v-for="option of role_id"
          :value="option.id"
          :key="option.id"
        >
          {{ option.name }}
        </option>
      </b-select>
    </b-field>

    <div v-if="UserData.role" class="mb-3">
      <b-field v-if="UserData.role.id === 3" label="Компания">
        <b-taginput
          v-model="selectedCompanies"
          :data="filteredCompanies"
          autocomplete
          :allow-new="false"
          :open-on-focus="true"
          field="name"
          placeholder="Выберите компании"
          :disabled="UserData.role.id !== 3"
          @typing="getCompaniesFilteredTags"
        />
      </b-field>
      <b-field
        label="Компания"
        v-else
      >
        <b-select
          placeholder="Выберите компанию"
          v-model="selectedCompany"
        >
          <option
            v-for="option of Company_list"
            :value="option.id"
            :key="option.id"
          >
            {{ option.name }}
          </option>
        </b-select>
      </b-field>
    </div>

    <b-field label="Филиал">
      <b-taginput
        v-model="BranchesData"
        :data="filteredBranches"
        autocomplete
        :allow-new="false"
        :open-on-focus="true"
        field="name"
        placeholder="Выберите филиал"
        :disabled="(UserData.role && UserData.role.id === 3) || filteredBranches.length === 0"
        @typing="getBranchesFilteredTags"
        @input="handleValueBranches"
      >
      </b-taginput>
    </b-field>

    <div class="is-flex my-3">
      <button
        class="is-flex is-align-self-flex-start button is-success"
        @click="SaveChangesUser"
      >Сохранить
      </button>
      <button class="is-flex is-align-self-flex-start mx-3 button is-warning">Деактивировать</button>
      <button
        class="is-flex is-align-self-flex-start button is-danger"
        @click="DeleteUser"
      >Удалить
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      filteredTags: [],
      BranchesData: [],
      selectedCompany: undefined,
      selectedBranches: [],
      selectedCompanies: [],
      filteredBranches: [],
      filteredCompanies: [],
      UserData: [],
      users_id: this.$router.history.current.params.id.replace(/[^0-9]/g, ""),
      user_id: null,
      role_id: [],
      password: null,
      Company_list: [],
      selected_company: null,
      CompanyBranches: [],
    };
  },
  watch: {
    selectedCompany(newValue) {
      this.GetCompanyBranches(newValue);
    },
  },
  created() {
    this.GetUserRole();
    this.GetCompanies().then(() => this.GetBranchUsers());
  },
  methods: {
    handleValueBranches(value) {
      this.selectedBranches = value.map(branch => branch.id)
    },
    getBranchesFilteredTags(text) {
      this.filteredBranches = this.CompanyBranches.filter((option) => {
        return option.name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    getCompaniesFilteredTags(text) {
      this.filteredCompanies = this.Company_list.filter((option) => {
        return option.name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0
      })
    },
    getFilteredTags(text) {
      this.filteredTags = this.CompanyBranches.filter((option) => {
        return option.name
          .toString()
          .toLowerCase()
          .indexOf(text.toLowerCase()) >= 0;
      });
    },
    async GetBranchUsers() {
      this.user_id = this.$router.history.current.params.id.replace(/[^0-9]/g, "");
      await this.$axios.get(`users/${this.user_id}`).then(response => {
        this.UserData = response.data.data;
        this.selectedCompany = response.data.data?.branch?.company?.id;
        this.selectedBranches = response.data.data.branches?.map(branch => branch.id) || [];

        const companies_ids = response.data.data?.companies_ids || []
        this.selectedCompanies = this.Company_list.filter(company => companies_ids.includes(company.id))
      });
    },
    async SaveChangesUser() {
      let data = {
        "login": this.UserData.login,
        "role_id": this.UserData.role.id,
        "first_name": this.UserData.first_name,
        "last_name": this.UserData.last_name,
        "position": this.UserData.position,
        "email": this.UserData.email,
        "phone": this.UserData.phone,
        ...(this.UserData.role.id !== 3 && { "branch_ids": this.selectedBranches }),
        ...(this.UserData.role.id === 3 ? {
          companies_ids: this.selectedCompanies.map(company => company.id)
        } : { company_id: this.selectedCompany }),
      };

      if (this.password != null) {
        data.password = this.password;
      }

      await this.$axios.put(`users/${this.users_id}`, data).then(response => {
        this.$router.push("/users-list");
      });
    },
    async GetUserRole() {
      await this.$axios.get(`users/create`).then(response => {
        this.role_id = response.data.data;
      });
    },
    async GetCompanies() {
      await this.$axios.get(`admin/companies`).then(response => {
        this.Company_list = response.data.data || [];
        this.filteredCompanies = response.data.data || []
      });
    },
    async GetCompanyBranches(e) {
      await this.$axios.get(`admin/companies/${e}/branches`).then(response => {
        this.filteredTags = response.data.data;
        this.CompanyBranches = response.data.data;
        this.filteredBranches = response.data.data;

        this.BranchesData = response.data.data.filter(branch => {
          return this.selectedBranches.includes(branch.id);
        });
      });
    },
    async DeleteUser() {
      await this.$axios.delete(`users/${this.user_id}`).then(() => {
        this.$router.push("/users-list");
      });
    },
  },
};
</script>

<style>
.main-box {
  background-color: white;
}
</style>
